
























































































































import Vue from 'vue';
import { SignIn } from '@/router/routes';
import i18n from '@/i18n';
import { UserToCreate } from '../store/users/types';
import { mapMutations } from 'vuex';
import { ADD_NOTIFICATION } from '../store/notifications';

import { mdiCheckCircle } from '@mdi/js';

// get forbidden char for an email address
function getForbiddenChar(v: string) {
  // split the string into an array of chars
  const chars = v.split('');
  // filter illegal chars
  const array = chars.filter((char) => !/^[a-zA-Z0-9_\.@-]*$/g.test(char));
  // remove duplicates and group items as a string (item are separated by "coma")
  const result = Array.from(new Set(array)).join(', ');
  return result;
}
function emailRule(value: string | null | undefined) {
  /**
   * match:
   * 1) letters, digits, hyphen, point and underscore.
   * 2) the @ character
   * 3) at least one letter followed by an optional hyphen
   * 4) at least one letter followed by a point
   * 5) between 2 and 3 letters
   */
  const regex = new RegExp(
    /[a-zA-Z0-9_.-]{2,}@[a-zA-Z]{1,}-?[a-zA-Z]{1,}\.[a-zA-Z]{2,3}$/,
  );

  if (!value) return i18n.t('invalidEmail') as string;

  // check if the value as the right format
  let res: boolean = regex.test(value.toLowerCase());

  // check if the value as forbidden chars
  let forbiddenChar = getForbiddenChar(value);

  // forbidden chars
  if (forbiddenChar !== '')
    return i18n.t('forbiddenCharacters', {
      forbiddenChar: `"${forbiddenChar}"`,
    }) as string;
  // invalid format
  if (!res) return i18n.t('invalidEmail') as string;

  return true;
}
function requiredRule(value: string) {
  return !!value || i18n.t('requiredField');
}

export default Vue.extend({
  name: 'SelfRegistration',
  data: () => {
    return {
      mdiCheckCircle,
      newUser: {} as UserToCreate,
      valid: false,
      isLoading: false,
      rules: {
        firstName: [(v: string) => requiredRule(v)],
        name: [(v: string) => requiredRule(v)],
        login: [(v: string) => requiredRule(v)],
        email: [(v: string) => requiredRule(v), (v: string) => emailRule(v)],
      },
      isSuccess: null,

      alert: {
        message: '',
      },
    };
  },
  methods: {
    ...mapMutations({
      addNotification: ADD_NOTIFICATION,
    }),
    toHome() {
      this.$router.push({
        name: SignIn.name,
        params: { lang: this.$i18n.locale },
      });
    },
    async submit() {
      this.isLoading = true;

      /** 30 sec  timeout */
      // TODO notify the user that the error is a timeout error
      //const t_id = setTimeout(() => (this.isLoading = false), 30000);

      try {
        this.isSuccess = await this.$http.users.requestSelfRegistration(
          Object.assign({}, this.newUser),
        );
        if (this.isSuccess === false) {
          this.addNotification({
            message: this.$t('selfRegistration.loginOrEmailAlreadyUsed'),
            color: 'error',
          });
        } else {
          this.alert.message = this.$i18n
            .t('selfRegistration.emailSuccessfullySent', {
              email: this.newUser.email,
            })
            .toString();
          return;
        }

        // TODO notify user that (s)he as a new email
      } catch (error) {
        switch (error.messageCode) {
          case 'cannotSendEmail':
            this.addNotification({
              color: 'error',
              message: this.$i18n
                .t(error.messageCode, { email: this.newUser.email })
                .toString(),
            });
            break;
          default:
            this.addNotification({
              color: 'error',
              message: this.$i18n.t(error.message).toString(),
            });
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
  computed: {
    newUserFirstNameLabel() {
      return this.$i18n.t('users.firstName');
    },
    newUserNameLabel() {
      return this.$i18n.t('users.name');
    },
    newUserLoginLabel() {
      return this.$i18n.t('users.login');
    },
    newUserEmailLabel() {
      return this.$i18n.t('users.email');
    },
  },
  watch: {
    '$i18n.locale'() {
      (this.$refs.selfRegistrationForm as any).validate();
    },
  },
});
